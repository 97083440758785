const getMousePos = (ev) => {
  return {
    x: ev.clientX,
    y: ev.clientY,
  };
};

const preloadImage = (urls) => {
  return new Promise((resolve) => {
    [...urls].forEach((url) => {
      const img = new Image();
      img.onload = resolve;
      img.onerror = resolve;
      img.src = url;
    });
  });
};

const imagesToPreload = [
  "https://res.cloudinary.com/fluidcoins/image/upload/q_auto,f_auto/useflip-landing/hero-effect-coin_i9lwyk.png",
  "https://res.cloudinary.com/fluidcoins/image/upload/q_auto,f_auto/useflip-landing/effect-goggles_gqn3cp.png",
  "https://res.cloudinary.com/fluidcoins/image/upload/q_auto,f_auto/useflip-landing/hero-image_jizxhp.png",
  // 'https://res.cloudinary.com/fluidcoins/image/upload/q_auto,f_auto/useflip-landing/hero-effect-star_odintd.png',
  "https://res.cloudinary.com/fluidcoins/image/upload/q_auto,f_auto/useflip-landing/hero-effect-shadow_rgxril.png",
  "https://res.cloudinary.com/fluidcoins/image/upload/q_auto,f_auto/useflip-landing/feature-image-mobile_kbjmen.png",
  "https://res.cloudinary.com/fluidcoins/image/upload/v1658325429/useflip-landing/feature-img_kcqnkn.png",
  "https://res.cloudinary.com/fluidcoins/image/upload/q_auto,f_auto/useflip-landing/flip-packed_l62org.png",
  "https://res.cloudinary.com/fluidcoins/image/upload/q_auto,f_auto/useflip-landing/savings-image_m0b7uh.png",
  "https://res.cloudinary.com/fluidcoins/image/upload/v1658325282/useflip-landing/spending-image_qibyyh.png",
  "https://res.cloudinary.com/fluidcoins/image/upload/q_auto,f_auto/useflip-landing/seamlessly-qr-code_e2uf7i.png",
  "https://res.cloudinary.com/fluidcoins/image/upload/q_auto,f_auto/useflip-landing/flip-tags_keykud.png",
  "https://res.cloudinary.com/fluidcoins/image/upload/q_auto,f_auto/useflip-landing/stablecoins_bgjle3.png",
  "https://res.cloudinary.com/fluidcoins/image/upload/q_auto,f_auto/useflip-landing/Earth_evdmdh.png",
  "https://res.cloudinary.com/fluidcoins/image/upload/q_auto,f_auto/useflip-landing/hero-effect-cup_v1l8q5.png",
  "https://res.cloudinary.com/fluidcoins/image/upload/v1655224721/useflip-landing/osita-osita-iheme_nqjvb1.gif",
  "https://res.cloudinary.com/fluidcoins/image/upload/v1655224657/useflip-landing/aki-and-pawpaw-money_t7xkrg.gif",
  "https://res.cloudinary.com/fluidcoins/image/upload/q_auto,f_auto/useflip-landing/download-effect-double-coin_uf3zcx.png",
  "https://res.cloudinary.com/fluidcoins/image/upload/q_auto,f_auto/useflip-landing/download-effect-coin_wa0cml.png",
  "https://res.cloudinary.com/fluidcoins/image/upload/q_auto,f_auto/useflip-landing/download-effect-double-coin-mobile_qdzbxu.png",
  "https://res.cloudinary.com/fluidcoins/image/upload/v1655119057/useflip-landing/hero-background-mobile_pka9nw.svg",
  "https://res.cloudinary.com/fluidcoins/image/upload/v1655118740/useflip-landing/background-coin-mobile_cgetnh.svg",
  "https://res.cloudinary.com/fluidcoins/image/upload/v1655110856/useflip-landing/spending-texture-mobile_rsjijl.svg",
  "https://res.cloudinary.com/fluidcoins/image/upload/v1655063774/useflip-landing/saving-texture-mobile_efkj66.svg",
  "https://res.cloudinary.com/fluidcoins/image/upload/v1654886746/useflip-landing/spending-texture_p1msoz.svg",
  "https://res.cloudinary.com/fluidcoins/image/upload/v1654882240/useflip-landing/savings-texture_k0rpla.svg",
  "https://res.cloudinary.com/fluidcoins/image/upload/v1655501963/useflip-landing/background-coin_b7sydr.svg",
  "https://res.cloudinary.com/fluidcoins/image/upload/v1654873769/useflip-landing/hero-texture._bp4dnu.svg",
  "https://res.cloudinary.com/fluidcoins/image/upload/v1654873477/useflip-landing/payouts-gradient_tzgijb.png",
  "https://res.cloudinary.com/fluidcoins/image/upload/v1654873477/useflip-landing/seamless-gradient_hyhzlw.png",
  "https://res.cloudinary.com/fluidcoins/image/upload/v1654873477/useflip-landing/flip-tag-radient_i0mwjz.png",
  "https://res.cloudinary.com/fluidcoins/image/upload/v1654873477/useflip-landing/flip-tags_yogsqt.png",
  "https://res.cloudinary.com/fluidcoins/image/upload/v1654871876/useflip-landing/download-texture_mctxc5.svg",
  "https://res.cloudinary.com/fluidcoins/image/upload/v1654871122/useflip-landing/hero-background_mzjwwa.svg",
];

export { getMousePos, preloadImage, imagesToPreload };
