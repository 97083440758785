import gsap from "gsap";
import LocomotiveScroll from "locomotive-scroll";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Typewriter from "typewriter-effect/dist/core";
import { getMousePos, preloadImage, imagesToPreload } from "./utils";

const scrollEl = document.querySelector("[data-scroll-container]");

gsap.registerPlugin(ScrollTrigger);

let mousePos = { x: 0, y: 0 };

const pinnedArr = [
  { sectionRef: ".savings", imgRef: ".savings__hover-reveal" },
  { sectionRef: ".spending", imgRef: ".spending__hover-reveal" },
];

export default class Home {
  constructor() {
    this.heroText();
    this.pinnedSection();
    this.typeWritterEffect();
    this.imageReveal(pinnedArr);
  }

  heroText() {
    gsap.from(".hero-text__title", {
      autoAlpha: 0,
      y: 50,
      ease: "power2.out",
    });
  }

  typeWritterEffect() {
    const typewriter = new Typewriter("#hero-text__typewriter", {
      strings: ["Savings", "Spendings", "Earnings"],
      autoStart: true,
      delay: 50,
      loop: true,
    });
  }

  pinnedSection() {
    if (window.innerWidth > 1024) {
      const sections = gsap.utils.toArray(".pinned__section");
      const lastIndex = sections.length - 1;
      sections.forEach((section, index) => {
        ScrollTrigger.create({
          trigger: section,
          scroller: "[data-scroll-container]",
          start: "bottom bottom",
          pin: true,
          pinSpacing: false,
          endTrigger: sections[lastIndex],
          end: "bottom bottom",
        });
      });
    }
  }

  imageReveal(sections) {
    sections.forEach((sectionItem) => {
      const section = document.querySelector(sectionItem.sectionRef);
      const img = document.querySelector(sectionItem.imgRef);

      const boundingRect = {
        el: img.getBoundingClientRect(),
        section: section.getBoundingClientRect(),
      };

      function onMotion(ev) {
        mousePos = getMousePos(ev);

        let mosPosX = mousePos.x - boundingRect.el.width / 2;
        let mosPosY = mousePos.y - boundingRect.el.height / 2;

        gsap.set(img, {
          zIndex: 20,
          left: mosPosX,
          top: mosPosY,
          duration: 1,
          ease: "Sine.easeOut",
          delay: 0.08,
        });

        gsap.set(img.children, {
          opacity: 1,
        });
      }

      section.addEventListener("mousemove", onMotion);
      section.addEventListener("mouseleave", () => {
        gsap.to(img.children, {
          opacity: 0,
          ease: "Sine.easeOut",
        });
      });
    });
  }
}

function preloader() {
  const scroll = new LocomotiveScroll({
    el: scrollEl,
    smooth: true,
    lerp: 0.06,
  });

  scroll.on("scroll", ScrollTrigger.update);

  ScrollTrigger.scrollerProxy(scroll.el, {
    scrollTop(value) {
      return arguments.length
        ? scroll.scrollTo(value, 0, 0)
        : scroll.scroll.instance.scroll.y;
    },

    getBoundingClientRect() {
      return {
        top: 0,
        left: 0,
        width: window.innerWidth,
        height: window.innerHeight,
      };
    },
  });

  setTimeout(() => {
    scroll.update();
  }, 1000);

  preloadImage(imagesToPreload).then(() => {
    gsap.to(".preloader__wrapper", {
      display: "none",
      duration: 2,
      onComplete: () => {
        new Home();
      },
    });
  });
}

preloader();
